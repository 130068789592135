<template>
  <div v-if="userInfo != null">
    <van-row class="uheadbox" type="flex" align="center">
      <van-col span="4" class="avatar"><img :src="userInfo.avatarUrl"/></van-col>
      <van-col span="20" class="nickName">{{userInfo.nickName}}</van-col>
    </van-row>
    <orderList :data="{hdid:hdid,status:activeKey==10?undefined:activeKey}" :order_type="2" :config="{type:3}"/>
    <global :hdid="hdid"/>
  </div>
</template>

<script>
  import orderList from '../components/orderList'
  import global from '../components/global'
  import {mapGetters} from "vuex";
  export default {
    name:'zhongjiang',
    components: {orderList,global},
    data() {
      return {
        activeKey:10,
        hdid:undefined
      }
    },
    computed: {
      ...mapGetters(['userInfo'])
    },
    created(){
      this.hdid=parseInt(this.$route.params.hdid)
    },
    mounted() {
    
    },
    methods: {
    
    }
  }
</script>
<style lang="less" scoped>


</style>
